import { notEmpty, scmpPlus } from "@product/scmp-sdk";
import enc from "crypto-js/enc-hex";
import SHA256 from "crypto-js/sha256";
import type { NextPageContext, PageProps } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { graphql, usePreloadedQuery } from "react-relay";
import type { RelayComponent } from "relay-nextjs";
import { withRelay } from "relay-nextjs";

import type { AppBarVariant } from "scmp-app/components/app-bar/types";
import { DefaultCustomContents } from "scmp-app/components/article/article-render/consts";
import { FallbackLoading } from "scmp-app/components/fallback-loading";
import { SectionContextProvider } from "scmp-app/components/section/contexts";
import { SectionSeo } from "scmp-app/components/section/section-seo";
import { SectionStyleSeo } from "scmp-app/components/section/section-style/section-style-seo";
import { SubSection } from "scmp-app/components/section/sub-section";
import { getIncludeSectionFeatureMap } from "scmp-app/components/section/sub-section/helpers";
import { SubSectionPostMagazine } from "scmp-app/components/section/sub-section/sub-section-post-magazine";
import { SubSectionStyle } from "scmp-app/components/section/sub-section/sub-section-style";
import { useTrackCurrentItem } from "scmp-app/lib/current-item";
import { createClientEnvironment } from "scmp-app/lib/relay/environment.client";
import { parseQueryString } from "scmp-app/lib/utils";
import { Variant as SectionVariant, Variant } from "scmp-app/pages/section/enums";
import type { subSectionPageQuery } from "scmp-app/queries/__generated__/subSectionPageQuery.graphql";

const query = graphql`
  query subSectionPageQuery(
    $after: String
    $articlesQueueName: String!
    $commentQueueName: String!
    $customContents: [CustomContentInput]
    $entityId: String!
    $first: Int
    $focusQueueName: String!
    $isStyleSubSection: Boolean!
    $isPostMagazineSubSection: Boolean!
    $isSkipDefaultSubSectionQuery: Boolean!
    $multimediaQueueName: String!
    $relatedSectionsQueueName: String!
    $scmpPlusPaywallTypeIds: [String]
    $parentSectionUuid: String!
    $relatedTopicsQueueName: String!
    $postMagazineSubSectionTopicQueueName: String!
    $hasParentSection: Boolean!
    $sponsorQueueName: String!
    $includeComment: Boolean!
    $includeChinaFutureTechDiscovery: Boolean!
    $includeFocusArticle: Boolean!
    $includeMultimedia: Boolean!
    $includePostMagazine: Boolean!
  ) {
    subSection: section(filter: { entityId: $entityId }) {
      ...contextsSectionContextProviderSection
      ...sectionSeoSection
      ...hooksTrackCurrentItemBase @arguments(customContents: $customContents)
    }

    ...subSectionQuery
      @arguments(
        entityId: $entityId
        articlesQueueName: $articlesQueueName
        commentQueueName: $commentQueueName
        multimediaQueueName: $multimediaQueueName
        scmpPlusPaywallTypeIds: $scmpPlusPaywallTypeIds
        after: $after
        first: $first
        focusQueueName: $focusQueueName
        parentSectionUuid: $parentSectionUuid
        relatedTopicsQueueName: $relatedTopicsQueueName
        hasParentSection: $hasParentSection
        includeComment: $includeComment
        includeChinaFutureTechDiscovery: $includeChinaFutureTechDiscovery
        includeFocusArticle: $includeFocusArticle
        includeMultimedia: $includeMultimedia
        includePostMagazine: $includePostMagazine
      )
      @skip(if: $isSkipDefaultSubSectionQuery)

    ...subSectionStyleContentQuery
      @arguments(
        after: $after
        articlesQueueName: $articlesQueueName
        entityId: $entityId
        first: $first
        relatedSectionsQueueName: $relatedSectionsQueueName
      )
      @include(if: $isStyleSubSection)
    ...subSectionPostMagazineQuery
      @arguments(
        after: $after
        articlesQueueName: $articlesQueueName
        entityId: $entityId
        first: $first
        postMagazineSubSectionTopicQueueName: $postMagazineSubSectionTopicQueueName
        sponsorQueueName: $sponsorQueueName
      )
      @include(if: $isPostMagazineSubSection)
  }
`;

const SubSectionPage: RelayComponent<PageProps, subSectionPageQuery> = ({
  preloadedQuery,
  randomSeed,
}) => {
  const data = usePreloadedQuery(query, preloadedQuery);

  const { trackCurrentItem } = useTrackCurrentItem();

  useEffect(() => {
    trackCurrentItem(data.subSection);
  }, [data.subSection, trackCurrentItem]);

  const { query: routeQuery } = useRouter();
  const handleRenderPageSeo = () => {
    switch (routeQuery.sectionVariant) {
      case Variant.Style:
        return <SectionStyleSeo reference={data.subSection} />;
      default:
        return <SectionSeo reference={data.subSection} twitter={{ cardType: "summary" }} />;
    }
  };

  const handleRenderSubSection = () => {
    switch (routeQuery.sectionVariant) {
      case Variant.PostMagazine:
        return (
          <SectionContextProvider reference={data.subSection}>
            <SubSectionPostMagazine randomSeed={randomSeed} reference={data} />
          </SectionContextProvider>
        );
      case Variant.Style:
        return (
          <SectionContextProvider reference={data.subSection}>
            <SubSectionStyle reference={data} />
          </SectionContextProvider>
        );
      default:
        return (
          <SectionContextProvider reference={data.subSection}>
            <SubSection reference={data} />
          </SectionContextProvider>
        );
    }
  };

  return (
    <>
      {handleRenderPageSeo()}
      {handleRenderSubSection()}
    </>
  );
};

SubSectionPage.displayName = "SubSectionPage";

const getPageProps = (context: NextPageContext) => {
  const isSectionStyle = context.query.sectionVariant === SectionVariant.Style;
  const isPostMagazine = context.query.sectionVariant === SectionVariant.PostMagazine;
  const pageProps: PageProps = {
    appBarConfiguration: {
      adSlotVariants: {
        large: {
          height: isSectionStyle ? 360 : 250,
          padding: isSectionStyle ? 24 : 20,
        },
        small: {
          height: 100,
          padding: 10,
        },
      },
      hasDesktopAd: !isPostMagazine,
      hasMobileAd: !isPostMagazine,
      variant: context.query.appBarVariant as AppBarVariant,
    },
    contentConfiguration: {
      responsivePadding: {
        desktopUp: 12,
        tabletUp: 0,
      },
    },
    headerConfiguration: {
      responsiveFeatures: isSectionStyle
        ? {
            desktopUp: {
              default: {
                left: ["logo-link-style", "text-link-style"],
                right: ["subscription", "my-news", "dashboard", "hamburger-menu"],
              },
            },
            largeDesktopUp: {
              default: {
                left: ["logo-link-style", "text-link-style"],
                right: ["subscription", "search", "my-news", "dashboard"],
              },
            },
            mediumDesktopUp: {
              default: {
                left: ["logo-link-style", "text-link-style"],
                right: ["subscription", "search", "my-news", "dashboard"],
              },
            },
            mobileUp: {
              default: { left: ["logo-link-style"], right: ["dashboard", "hamburger-menu"] },
              nonSubscriberSwapped: {
                left: ["logo-link-style", "text-link-style"],
                right: ["dashboard", "hamburger-menu"],
              },
              swapped: {
                left: ["logo-link-style", "text-link-style"],
                right: ["dashboard", "hamburger-menu"],
              },
            },
            tabletUp: {
              default: {
                left: ["logo-link-style", "text-link-style"],
                right: ["subscription", "my-news", "dashboard", "hamburger-menu"],
              },
            },
          }
        : undefined,
    },
    pageConfiguration: {
      responsivePadding: {
        tabletUp: 24,
      },
    },
  };

  return pageProps;
};

const generateRandomSeed = () => SHA256(new Date().toString()).toString(enc);

export default withRelay(SubSectionPage, query, {
  clientSideProps: context => ({ ...getPageProps(context), randomSeed: generateRandomSeed() }),
  createClientEnvironment: () => createClientEnvironment(),
  createServerEnvironment: async context => {
    const { createServerEnvironment } = await import("scmp-app/lib/relay/environment.server");
    return createServerEnvironment(context.req?.cookies);
  },
  fallback: <FallbackLoading />,
  serverSideProps: context =>
    Promise.resolve({ ...getPageProps(context), randomSeed: generateRandomSeed() }),
  variablesFromContext(context) {
    const parentSectionUuid = parseQueryString(context.query.parentSection) ?? "";
    const entityId = parseQueryString(context.query.entityId) ?? "";

    const queueNames = {
      articlesQueueName: `section_top_${entityId}`,
      commentQueueName: `section_top_opinion_${entityId}`,
      focusQueueName: `section_top_focus_${entityId}`,
      multimediaQueueName: `section_top_visual_stories_${entityId}`,
      postMagazineSubSectionTopicQueueName: `related_topics_${entityId}`,
      relatedSectionsQueueName:
        parseQueryString(context.query.relatedSectionsQueueName) ??
        `section_categories_${entityId}`,
      relatedTopicsQueueName: `related_topics_${entityId}`,
      sponsorQueueName: `section_brand_post_${entityId}`,
      subSectionsQueueName: `section_categories_${entityId}`,
    };

    return {
      ...queueNames,
      ...getIncludeSectionFeatureMap(entityId),
      customContents: DefaultCustomContents,
      entityId,
      first: 25,
      hasParentSection: notEmpty(parentSectionUuid),
      isPostMagazineSubSection: context.query.sectionVariant === Variant.PostMagazine,
      isSkipDefaultSubSectionQuery:
        context.query.sectionVariant === SectionVariant.Style ||
        context.query.sectionVariant === SectionVariant.PostMagazine,
      isStyleSubSection: context.query.sectionVariant === Variant.Style,
      parentSectionUuid,
      scmpPlusPaywallTypeIds: [scmpPlus?.article?.paywallTypeId],
    };
  },
});
