import { notEmpty, theme } from "@product/scmp-sdk";
import type { FunctionComponent, ReactNode } from "react";
import { Fragment, useCallback, useMemo } from "react";
import { graphql, usePaginationFragment } from "react-relay";

import { useSectionContext } from "scmp-app/components/section/contexts";
import { useInfiniteScrollTriggerReference } from "scmp-app/lib/hooks";
import type { subSectionStyleArticleListPaginationQuery } from "scmp-app/queries/__generated__/subSectionStyleArticleListPaginationQuery.graphql";
import type { subSectionStyleArticleListQuery$key } from "scmp-app/queries/__generated__/subSectionStyleArticleListQuery.graphql";

import {
  ArticleContainer,
  BannerAdSlot,
  Container,
  InlineAdSlot,
  InlineAdSlotContainer,
  StyledContentItemMagazinesStyleCardSquare,
  StyledLoading,
} from "./styles";

const ARTICLE_GROUP_SIZE = 5;
const ARTICLE_PAGE_SIZE = ARTICLE_GROUP_SIZE * 3;

type Props = {
  children?: ReactNode;
  className?: string;
  reference: subSectionStyleArticleListQuery$key;
};
export const SubSectionStyleArticleList: FunctionComponent<Props> = ({
  children,
  className,
  reference,
}) => {
  const {
    data,
    hasNext: hasNextPage,
    isLoadingNext: isLoading,
    loadNext,
  } = usePaginationFragment<
    subSectionStyleArticleListPaginationQuery,
    subSectionStyleArticleListQuery$key
  >(
    graphql`
      fragment subSectionStyleArticleListQuery on Query
      @argumentDefinitions(
        after: { type: "String" }
        articlesQueueName: { type: "String!" }
        first: { type: "Int", defaultValue: 30 }
      )
      @refetchable(queryName: "subSectionStyleArticleListPaginationQuery") {
        sectionArticles: queue(filter: { name: $articlesQueueName }) {
          items(first: $first, after: $after)
            @connection(key: "styleSectionArticleListQuery__items") {
            edges {
              node {
                ... on Article {
                  ...magazinesStyleCardContentItemContent
                }
              }
            }
          }
        }
      }
    `,
    reference,
  );

  const articles = useMemo(() => data.sectionArticles?.items?.edges.filter(notEmpty) ?? [], [data]);

  const { advertisement: baseAdvertisement } = useSectionContext();
  const { infiniteScrollTriggerReference } = useInfiniteScrollTriggerReference({
    hasNextPage,
    isLoading,
    onLoadMore: () => loadNext(ARTICLE_PAGE_SIZE),
  });

  const handleRenderInnerAd = useCallback(
    (groupIndex: number) => {
      const mapIndexToAdUnitSuffix = (index: number) => {
        switch (index) {
          case 0:
            return 1;
          case 1:
            return 3;
          default:
            return 2;
        }
      };
      const mobileAdStartIndex = 1; // index 0 is skipped to avoid having double ad with banner ad is render as the use the same ui location

      return {
        desktop: (
          <InlineAdSlot
            {...baseAdvertisement}
            adUnit={`d_lrec${mapIndexToAdUnitSuffix(groupIndex)}`}
            breakpoint={theme.breakpoints.up("desktop")}
            sizes={[[300, 250], [300, 600], "fluid"]}
            stickyLabel
            stickySpacing={40}
            withLabel
            withSticky
          />
        ),
        mobile:
          groupIndex < mobileAdStartIndex ? null : (
            <InlineAdSlot
              {...baseAdvertisement}
              adUnit={`m_lrec${mapIndexToAdUnitSuffix(groupIndex - mobileAdStartIndex)}`}
              breakpoint={theme.breakpoints.only("mobile")}
              sizes={[[300, 250], [320, 480], "fluid"]}
              withLabel
            />
          ),
        tablet: (
          <InlineAdSlot
            {...baseAdvertisement}
            adUnit={`m_lrec${mapIndexToAdUnitSuffix(groupIndex)}`}
            breakpoint={theme.breakpoints.only("tablet")}
            sizes={[[300, 250], "fluid"]}
            stickyLabel
            stickySpacing={40}
            withLabel
            withSticky
          />
        ),
      };
    },
    [baseAdvertisement],
  );

  const handleRenderBannerAd = useCallback(
    (groupIndex: number) => {
      switch (groupIndex) {
        case 0:
          return null;
        case 1:
          return (
            <>
              <BannerAdSlot
                {...baseAdvertisement}
                adUnit="m_banner1"
                breakpoint={theme.breakpoints.only("mobile")}
                sizes={[[300, 250], [1, 1], "fluid"]}
                withLabel
              />
              <BannerAdSlot
                {...baseAdvertisement}
                adUnit="m_banner4"
                breakpoint={theme.breakpoints.only("tablet")}
                sizes={[[320, 100], [300, 250], [1, 1], "fluid"]}
                withLabel
              />
              <BannerAdSlot
                {...baseAdvertisement}
                adUnit="d_banner5"
                breakpoint={theme.breakpoints.up("desktop")}
                sizes={[[970, 250], [300, 250], [1, 1], "fluid"]}
                withLabel
              />
            </>
          );
        default:
          return (
            <BannerAdSlot
              {...baseAdvertisement}
              adUnit="d_banner2"
              breakpoint={theme.breakpoints.up("desktop")}
              sizes={[[970, 250], "fluid"]}
              withLabel
            />
          );
      }
    },
    [baseAdvertisement],
  );

  return (
    <>
      <Container className={className}>
        <ArticleContainer>
          {articles.map((article, index) => {
            const isMainArticle = index % ARTICLE_GROUP_SIZE === 0;
            const isLastArticleInGroup = (index + 1) % ARTICLE_GROUP_SIZE === 0;
            const groupIndex = Math.floor(index / ARTICLE_GROUP_SIZE);
            return (
              <Fragment key={index}>
                {isMainArticle && handleRenderBannerAd(groupIndex)}
                <StyledContentItemMagazinesStyleCardSquare reference={article.node} />
                {isMainArticle && (
                  <InlineAdSlotContainer>
                    {handleRenderInnerAd(groupIndex).desktop}
                    {handleRenderInnerAd(groupIndex).tablet}
                  </InlineAdSlotContainer>
                )}
                {isLastArticleInGroup && handleRenderInnerAd(groupIndex).mobile}
              </Fragment>
            );
          })}
        </ArticleContainer>
        {hasNextPage && (
          <StyledLoading ref={infiniteScrollTriggerReference}>LOADING ...</StyledLoading>
        )}
      </Container>
      {!hasNextPage && children}
    </>
  );
};

SubSectionStyleArticleList.displayName = "SubSectionStyleArticleList";
