/* eslint-disable max-lines */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRobotoCondensed, theme } from "@product/scmp-sdk";

import { section as sectionData } from "shared/data/section";

import { Loading } from "scmp-app/components/common/loading";
import { StyledFigcaption } from "scmp-app/components/content/content-cover-image/styles";
import { CoverImage as HomePrimaryCoverImage } from "scmp-app/components/content/content-item-render/variants/home-primary/styles";
import {
  Primary as SectionTopLeadPrimaryPrimary,
  SectionContainer as SectionTopLeadPrimarySectionContainer,
} from "scmp-app/components/content/content-item-render/variants/section-top-lead-primary/styles";
import { EntityLink } from "scmp-app/components/entity-link";
import { HomeFocusArticle } from "scmp-app/components/home/focus-article";
import { Logo as SectionLogo } from "scmp-app/components/home/term-logo/styles";
import { Container as SectionDescription } from "scmp-app/components/section/section-description/styles";
import {
  Primary as SectionTopWidgetOnePrimary,
  PrimaryRest as SectionTopWidgetOnePrimaryRest,
  StyledContentItemHomeSecondary as SectionTopWidgetOneStyledContentItemHomeSecondary,
} from "scmp-app/components/section/section-top/variant/one/styles";
import { TopStoriesBlock } from "scmp-app/components/top-stories-block";
import { StyledContentItemHomePrimary } from "scmp-app/components/top-stories-block/top-stories-item/styles";
import { setFullViewportWidthStyles } from "scmp-app/lib/styles";

const largeBorderStyle = css`
  border-block-start: 1px solid rgba(0, 0, 0, 0.2);
`;

const smallBorderStyle = css`
  border-block-start: 1px solid rgba(0, 0, 0, 0.1);
`;

const borderStartSmallStyle = css`
  margin-block-start: 20px;
  padding-block-start: 20px;
  ${smallBorderStyle};
  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
    padding-block-start: 32px;
    ${largeBorderStyle};
  }
`;

const borderStartLargeStyle = css`
  margin-block-start: 32px;
  padding-block-start: 32px;
  ${smallBorderStyle};
  ${theme.breakpoints.up("tablet")} {
    ${largeBorderStyle};
  }
`;

const rowGap = css`
  margin-block-start: 20px;
  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
    /* special case for header */
    &:first-child {
      margin-block-start: 24px;
    }
  }
  ${theme.breakpoints.up("desktop")} {
    &:first-child {
      margin-block-start: 32px;
    }
  }
`;

export const StyledTopStoriesBlock = styled(TopStoriesBlock)`
  ${StyledContentItemHomePrimary} {
    display: flex;
    flex-direction: column-reverse;
  }

  ${HomePrimaryCoverImage} {
    margin-block-end: 12px;
    ${StyledFigcaption} {
      display: none;
    }
    ${theme.breakpoints.up("tablet")} {
      margin-block-end: 20px;
    }
  }
`;

export const StyledEntityLink = styled(EntityLink)``;

export const Container = styled.div`
  inline-size: 100%;
  ${rowGap};
  ${setFullViewportWidthStyles}
`;

export const ContentContainer = styled.div`
  max-inline-size: 1024px;
  margin-inline: auto;
  padding-block-end: 32px;
  padding-inline: 20px;

  ${theme.breakpoints.up("tablet")} {
    padding-inline: 32px;
  }
  ${theme.breakpoints.up("desktop")} {
    max-inline-size: 1272px;
    padding-block-end: 48px;
  }
`;

const getGridLayoutBySection = (
  parentSectionEntityId: string,
  subSectionEntityId: string,
  isShowAds: boolean,
) => {
  switch (subSectionEntityId) {
    case sectionData.announcements.entityId:
    case sectionData.recap.entityId:
      return css`
        grid:
          "header header" min-content
          "hero-articles first-ads" min-content
          "top-stories-1 ." min-content
          "top-stories-2 second-ads" min-content
          "top-stories-2 ." min-content
          "top-stories-3 third-ads" min-content
          "top-stories-4 ." min-content
          "subscription-message ." min-content
          "top-stories-5 fourth-ads" min-content
          "top-stories-6 ." min-content
          "top-stories-rest ." min-content
          "newsletter ." min-content
          / minmax(0, 1fr) minmax(0, 300px);

        ${RHSModule2} {
          margin-block-start: 0;
        }
        ${RHSModule4} {
          display: none;
        }
      `;
    case sectionData.asia.eastAsia.entityId:
    case sectionData.asia.southAsia.entityId:
    case sectionData.business.bankingAndFinance.entityId:
    case sectionData.business.chinaEVs.entityId:
    case sectionData.business.climateAndEnergy.entityId:
    case sectionData.business.companies.entityId:
    case sectionData.business.cryptocurrency.entityId:
    case sectionData.business.markets.entityId:
    case sectionData.chinaFutureTech.aerospace.entityId:
    case sectionData.chinaFutureTech.ai.entityId:
    case sectionData.chinaFutureTech.biomedicine.entityId:
    case sectionData.chinaFutureTech.evs.entityId:
    case sectionData.chinaFutureTech.robotics.entityId:
    case sectionData.chinaFutureTech.semiconductors.entityId:
    case sectionData.comment.asiaOpinion.entityId:
    case sectionData.comment.chinaOpinion.entityId:
    case sectionData.comment.worldOpinion.entityId:
    case sectionData.economy.chinaEconomy.entityId:
    case sectionData.economy.economicIndicators.entityId:
    case sectionData.economy.globalEconomy.entityId:
    case sectionData.economy.policy.entityId:
    case sectionData.lifestyle.artsAndCulture.entityId:
    case sectionData.lifestyle.familyAndRelationships.entityId:
    case sectionData.lifestyle.foodAndDrink.entityId:
    case sectionData.lifestyle.healthAndWellness.entityId:
    case sectionData.lifestyle.hundredTopTables.entityId:
    case sectionData.lifestyle.travelAndLeisure.entityId:
    case sectionData.news.china.diplomacy.entityId:
    case sectionData.news.china.politics.entityId:
    case sectionData.news.hongKong.healthAndEnvironment.entityId:
    case sectionData.news.hongKong.hongKongEconomy.entityId:
    case sectionData.news.hongKong.politics.entityId:
    case sectionData.news.peopleAndCulture.chinaPersonalities.entityId:
    case sectionData.news.peopleAndCulture.environment.entityId:
    case sectionData.news.peopleAndCulture.genderAndDiversity.entityId:
    case sectionData.news.peopleAndCulture.socialWelfare.entityId:
    case sectionData.news.peopleAndCulture.trendingInChina.entityId:
    case sectionData.tech.bigTech.entityId:
    case sectionData.tech.policy.entityId:
    case sectionData.tech.techTrends.entityId:
    case sectionData.tech.techWar.entityId:
    case sectionData.thisWeekInAsia.economics.entityId:
    case sectionData.thisWeekInAsia.opinion.entityId:
    case sectionData.us.usElections.analysis.entityId:
    case sectionData.us.usElections.explainers.entityId:
    case sectionData.us.usElections.latest.entityId:
    case sectionData.world.africa.entityId:
    case sectionData.world.americas.entityId:
    case sectionData.world.europe.entityId:
    case sectionData.world.middleEast.entityId:
    case sectionData.world.russiaCentralAsia.entityId:
    case sectionData.world.unitedStatesCanada.entityId:
      return css`
        grid:
          "header header" min-content
          "section-description first-ads" min-content
          "hero-articles first-ads" min-content
          "top-stories-1 rhs-module-1" min-content
          "top-stories-2 rhs-module-1" min-content
          "top-stories-3 rhs-module-1" min-content
          "top-stories-4 ." min-content
          "subscription-message ." min-content
          "top-stories-5 second-ads" min-content
          "top-stories-5 ." 32px
          "top-stories-5 rhs-module-2" min-content
          "top-stories-6 rhs-module-2" min-content
          "top-stories-6 third-ads" min-content
          "top-stories-6 ." min-content
          "top-stories-rest rhs-module-3" min-content
          "top-stories-rest fourth-ads" min-content
          "top-stories-rest rhs-module-4" 1fr
          "newsletter ." min-content
          / minmax(0, 1fr) minmax(0, 300px);

        ${RHSModule2} {
          margin-block-start: 0;
          padding-block-start: 0;
        }

        ${ThirdAds} {
          ${isShowAds ? "" : "display: none;"}
        }

        ${HeroArticles} {
          margin-block-end: 32px;
        }

        ${TopStories1} {
          margin-block-start: 0;
        }
      `;
    default:
      return css`
        grid:
          "header header" min-content
          "hero-articles first-ads" min-content
          "top-stories-1 rhs-module-1" min-content
          "top-stories-2 rhs-module-1" min-content
          "top-stories-2 second-ads" min-content
          "top-stories-2 ." min-content
          "top-stories-3 rhs-module-2" min-content
          "top-stories-3 third-ads" min-content
          "top-stories-4 third-ads" min-content
          "top-stories-4 ." min-content
          "subscription-message ." min-content
          "top-stories-5 rhs-module-3" min-content
          "top-stories-6 rhs-module-3" min-content
          "top-stories-6 fourth-ads" min-content
          "top-stories-6 ." min-content
          "top-stories-rest ." min-content
          "newsletter ." min-content
          / minmax(0, 1fr) minmax(0, 300px);

        ${RHSModule2} {
          margin-block-start: 0;
        }
        ${RHSModule4} {
          display: none;
        }
      `;
  }
};

type ContentWrapperProps = {
  $isShowAds?: boolean;
  $parentSectionEntityId?: string;
  $subSectionEntityId?: string;
};
export const ContentWrapper = styled.div<ContentWrapperProps>`
  display: grid;
  grid:
    "header" min-content
    "section-description" min-content
    "hero-articles" min-content
    "first-ads" min-content
    "top-stories-1" min-content
    "rhs-module-1" min-content
    "second-ads" min-content
    "top-stories-2" min-content
    "rhs-module-2" min-content
    "third-ads" min-content
    "top-stories-3" min-content
    "top-stories-4" min-content
    "subscription-message" min-content
    "top-stories-5" min-content
    "rhs-module-3" min-content
    "fourth-ads" min-content
    "top-stories-6" min-content
    "rhs-module-4" min-content
    "top-stories-rest" min-content
    "newsletter" min-content
    / minmax(0, 1fr);

  ${theme.breakpoints.up("desktop")} {
    ${props =>
      getGridLayoutBySection(
        props.$parentSectionEntityId ?? "",
        props.$subSectionEntityId ?? "",
        props.$isShowAds ?? false,
      )};
    column-gap: 32px;
  }

  time {
    line-height: 14px;
  }
`;

export const Header = styled.div`
  grid-area: header;

  display: grid;
  grid:
    "section-logo breadcrumb" min-content
    "section-logo page-header" min-content
    "topic-menu topic-menu" min-content
    / min-content 1fr;
  align-items: center;

  padding-block-end: 16px;
  border-block-end: 1px solid rgba(0, 0, 0, 0.1);

  ${props => props.theme.breakpoints.up("tablet")} {
    grid:
      "section-logo breadcrumb" min-content
      "section-logo page-header" min-content
      "section-logo topic-menu" min-content
      / min-content 1fr;
    align-items: flex-start;
  }

  ${props => props.theme.breakpoints.up("desktop")} {
    padding-block-end: 20px;
  }

  h1 {
    grid-area: page-header;

    font-weight: 700;
    font-size: 24px;
    font-family: ${fontRobotoCondensed};
    line-height: 120%;
    text-transform: uppercase;

    ${props => props.theme.breakpoints.up("tablet")} {
      font-size: 32px;
    }

    ${props => props.theme.breakpoints.up("desktop")} {
      font-size: 48px;
    }
  }

  ${StyledEntityLink} {
    grid-area: breadcrumb;

    color: #808080;
    font-weight: 400;
    font-size: 14px;
    font-family: ${fontRobotoCondensed};
    text-transform: uppercase;

    transition: color 0.2s ease-in-out;

    &:hover {
      color: #2957b5;
    }

    ${props => props.theme.breakpoints.up("tablet")} {
      font-size: 16px;
    }

    ${props => props.theme.breakpoints.up("desktop")} {
      font-size: 20px;
    }
  }

  ${SectionLogo} {
    grid-area: section-logo;

    inline-size: 60px;
    block-size: 60px;
    margin-inline-end: 16px;
    ${props => props.theme.breakpoints.up("tablet")} {
      inline-size: 80px;
      block-size: 80px;
      margin-inline-end: 20px;
    }
  }
`;

type RelatedTopicContainerProps = {
  $withSectionLogo: boolean;
};
export const RelatedTopicContainer = styled.div<RelatedTopicContainerProps>`
  grid-area: topic-menu;

  display: flex;
  align-items: center;

  inline-size: 100%;
  margin-block-start: ${props => (props.$withSectionLogo ? "16px" : "8px")};

  font-weight: 700;
  line-height: 140%;
  white-space: nowrap;
  column-gap: 20px;

  overflow: hidden;
  ${props => props.theme.breakpoints.up("desktop")} {
    margin-block-start: 20px;
  }
`;

export const HeroArticles = styled.div`
  grid-area: hero-articles;

  margin-block-start: 16px;
  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
  }

  ${SectionTopWidgetOnePrimary} {
    display: flex;
    flex-direction: column-reverse;

    margin-block-end: 0;
    padding-block-end: 0;
    border-block-end: none;
  }
  ${SectionTopLeadPrimarySectionContainer} {
    display: none;
  }
  ${SectionTopLeadPrimaryPrimary} {
    margin-block-end: 16px;
    padding-block-end: 16px;

    ${theme.breakpoints.up("tablet")} {
      margin-block-end: 0;
      padding-block-end: 0;
    }
  }
  ${SectionTopWidgetOnePrimaryRest} {
    ${SectionTopWidgetOneStyledContentItemHomeSecondary} {
      &:not(:last-child) {
        margin-block-end: 16px;
        padding-block-end: 16px;
      }
    }
  }
`;

export const TopStories1 = styled.div`
  grid-area: top-stories-1;

  ${borderStartLargeStyle};
  ${theme.breakpoints.up("tablet")} {
    border-block-start: none;
  }
  ${theme.breakpoints.up("desktop")} {
    ${largeBorderStyle};

    margin-block-start: 32px;
  }
`;

export const TopStories2 = styled.div`
  grid-area: top-stories-2;
  ${borderStartSmallStyle};
`;

export const TopStories3 = styled.div`
  grid-area: top-stories-3;
  ${borderStartSmallStyle};
`;

export const TopStories4 = styled.div`
  grid-area: top-stories-4;
  ${borderStartLargeStyle};
`;

export const TopStories5 = styled.div`
  grid-area: top-stories-5;
  ${borderStartLargeStyle};
  margin-block-start: 46px;
  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
  }
`;

export const TopStories6 = styled.div`
  grid-area: top-stories-6;
  ${borderStartLargeStyle};
  ${theme.breakpoints.up("tablet")} {
    border-block-start: none;

    padding-block-start: 0;
  }
  ${theme.breakpoints.up("desktop")} {
    ${largeBorderStyle};

    padding-block-start: 32px;
  }
`;

export const TopStoriesRest = styled.div`
  grid-area: top-stories-rest;
`;

export const TopStoriesRestArticle = styled.div`
  ${borderStartLargeStyle}
`;

export const RHSModule1 = styled.div`
  grid-area: rhs-module-1;

  ${borderStartSmallStyle}
  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 32px;
    padding-block-start: 0;
    border-block-start: none;
  }
`;

export const RHSModule2 = styled.div`
  grid-area: rhs-module-2;

  ${borderStartSmallStyle}
  ${theme.breakpoints.up("tablet")} {
    border-block-start: none;
  }
`;

export const RHSModule3 = styled.div`
  grid-area: rhs-module-3;

  margin-block-start: 32px;
`;

export const RHSModule4 = styled.div`
  grid-area: rhs-module-4;

  margin-block-start: 32px;
`;

export const FirstAds = styled.div`
  grid-area: first-ads;

  ${borderStartLargeStyle};
  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 24px;
  }
  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 32px;
    padding-block-start: 0;
    border-block-start: none;
  }
`;

export const SecondAds = styled.div`
  grid-area: second-ads;

  ${rowGap};
`;

export const ThirdAds = styled.div`
  grid-area: third-ads;

  ${rowGap};
`;

export const FourthAds = styled.div`
  grid-area: fourth-ads;

  margin-block-start: 32px;
`;

export const SubscriptionMessage = styled.div`
  grid-area: subscription-message;

  margin-block-start: 32px;
`;

export const Newsletter = styled.div`
  grid-area: newsletter;
  ${borderStartLargeStyle};
`;

export const StyledLoading = styled(Loading)`
  padding-block: 60px;
`;

export const CommentContainer = styled.div``;
export const MultimediaContainer = styled.div``;
export const TrendingTopicContainer = styled.div``;

export const StyledHomeFocusArticle = styled(HomeFocusArticle)`
  border-block-start: 1px solid #000000;

  padding-block-start: 12px;
`;

export const SectionDescriptionContainer = styled.div`
  grid-area: section-description;

  display: none;
  &:not(:empty) {
    display: block;
    ${SectionDescription} {
      inline-size: 100%;
      max-inline-size: 100%;
      margin-block-start: 16px;

      ${props => props.theme.breakpoints.up("tablet")} {
        max-inline-size: 100%;
        margin-block-start: 32px;
      }
    }
  }
`;

export const SubSectionName = styled.h1`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;

  ${props => props.theme.breakpoints.up("tablet")} {
    justify-content: unset;
  }
`;
