/**
 * @generated SignedSource<<93f1e6d417ba234dfd7d746ed346e38f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksSubSectionWidgetAndAdsQuery$data = {
  readonly parentSection: {
    readonly entityId: string;
  };
  readonly subSection: {
    readonly advertZone: string | null | undefined;
    readonly entityId: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"chinaFutureTechDiscoveryWidgetQuery" | "commentQueueQuery" | "discoveryWidgetPostMagazineQueueQuery" | "focusArticleHomeFocusArticleQuery" | "hundredTopTablesDiscoveryWidgetQuery" | "multimediaQuery" | "openQuestionsSeriesDiscoveryWidgetQuery">;
  readonly " $fragmentType": "hooksSubSectionWidgetAndAdsQuery";
};
export type hooksSubSectionWidgetAndAdsQuery$key = {
  readonly " $data"?: hooksSubSectionWidgetAndAdsQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksSubSectionWidgetAndAdsQuery">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hooksSubSectionWidgetAndAdsQuery"
};

(node as any).hash = "9760c65fe1559c1387ace7c15d4f3afe";

export default node;
